// General
$transitionTiming: cubic-bezier(0.35, 0, 0.25, 1);
$sidebarTrnTime: 0.3s;

// Widths
$sidebar-width: 250px !default;

// Typography
// $font-family-base: Roboto, "Helvetica Neue", sans-serif !default;
// $font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif !default;
// -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif
$font-family-base: Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif !default;
$font-size-base: .875rem !default;
$font-weight-base: 400 !default;

$font-size-h1: 2.5rem !default;
$font-size-h2: 2rem !default;
$font-size-h3: 1.75rem !default;
$font-size-h4: 1.5rem !default;
$font-size-h5: 1.25rem !default;
$font-size-h6: 1rem !default;

$headings-margin-bottom: .5rem !default;
$headings-font-weight: 400 !default;
$headings-line-height: 1.1 !default;
$headings-color: inherit !default;
