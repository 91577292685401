h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: $headings-margin-bottom;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1 { font-size: $font-size-h1; }
h2 { font-size: $font-size-h2; }
h3 { font-size: $font-size-h3; }
h4 { font-size: $font-size-h4; }
h5 { font-size: $font-size-h5; }
h6 { font-size: $font-size-h6; }

body {
  &.font-s {
    h1 { font-size: $font-size-h1 * $font-size-small-scale; }
    h2 { font-size: $font-size-h2 * $font-size-small-scale; }
    h3 { font-size: $font-size-h3 * $font-size-small-scale; }
    h4 { font-size: $font-size-h4 * $font-size-small-scale; }
    h5 { font-size: $font-size-h5 * $font-size-small-scale; }
    h6 { font-size: $font-size-h6 * $font-size-small-scale; }
  }

  &.font-l {
    h1 { font-size: $font-size-h1 * $font-size-large-scale; }
    h2 { font-size: $font-size-h2 * $font-size-large-scale; }
    h3 { font-size: $font-size-h3 * $font-size-large-scale; }
    h4 { font-size: $font-size-h4 * $font-size-large-scale; }
    h5 { font-size: $font-size-h5 * $font-size-large-scale; }
    h6 { font-size: $font-size-h6 * $font-size-large-scale; }
  }

  &.font-xl {
    h1 { font-size: $font-size-h1 * $font-size-xlarge-scale; }
    h2 { font-size: $font-size-h2 * $font-size-xlarge-scale; }
    h3 { font-size: $font-size-h3 * $font-size-xlarge-scale; }
    h4 { font-size: $font-size-h4 * $font-size-xlarge-scale; }
    h5 { font-size: $font-size-h5 * $font-size-xlarge-scale; }
    h6 { font-size: $font-size-h6 * $font-size-xlarge-scale; }
  }
}

a,
a:focus,
a:hover {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  outline: 0;
}

button {
  outline: 0;
}

figure {
  margin: 0;
}

strong,
b {
  font-weight: 700;
}

.strikethrough {
  text-decoration: line-through;
}

.error-message {
  font-size: 12px;
}
