.action-bar-container {
  position: fixed;
  bottom: 24px;
  left: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // margin-left: -325px;
  animation: fadein 0.5s;

  .action-bar {
    background: rgba(88, 183, 164, 0.05);
    border-radius: 4px;
    box-sizing: border-box;
    min-width: 344px;
    padding: 14px 16px;
    min-height: 48px;
    transform-origin: center;
    display: flex;
    // fxLayoutAlign="end center"
    justify-items: flex-end;
    align-items: center;

    span {
      white-space: nowrap;
    }

    > *:not(:last-child) {
      margin-right: 1rem;
    }
  }

  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 24px; opacity: 1;}
  }
}
