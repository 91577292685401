/*---- Page (Not found) -----*/
.page-wrap {
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
}

.patient {
  .appointments {
    padding: 20px 28px;
  }
  .appointments, .invoices, .invoice, .intakes, .appointment-reschedule, .appointment-schedule, .appointment-survey {
    padding-top: 20px;
    .section-title {
      font-size: 28px;
      line-height: 33px;
    }
    .section-subtitle {
      font-size: 20px;
      line-height: 23px;
      color: var(--primary-text-color);
    }
  }
}
