@import '../variables';

html {
  font-size: 16px;
}

html:not(.landing),
body:not(.landing) {
  width: 100%;
  height: 100%;
  position: relative;
  // overflow: hidden;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  font-weight: $font-weight-base;
  font-size: $font-size-base;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.5;
  font-family: $font-family-base;

  --header-height: #{$header-height};
}

[tabindex='-1']:focus {
  outline: none;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}
textarea {
  height: 55px;
}
div {
  box-sizing: border-box;
}
html, body {
  &[dir=rtl], &[dir=ltr] {
    unicode-bidi: embed
  }
}
bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}
bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

img:not(.mat-mdc-card-image) {
  max-width: 100%;
  &.logo-clinic {
    max-width: 200px;
    max-height: 65px;
  }
}
a, a:focus, a:hover {
  text-decoration: none;
}
a {
  color: inherit;
}
p {
  margin: 0 0 16px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: .5rem;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
}
.h1,
h1 {
  font-size: 2rem;
}
.h2,
h2 {
  font-size: 1.75rem;
}
.h3,
h3 {
  font-size: 1.5rem;
}
.h4,
h4 {
  font-size: 1.25rem;
}
.h5,
h5 {
  font-size: 1rem;
}
.h6,
h6 {
  font-size: .875rem;
}
code {
  padding: 8px;
  background: rgba(0, 0, 0, .08);
}
/*---- Common -----*/
.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 15px;

  &--small {
    max-width: 768px;
    padding: 0;
  }
}
.bg-none {
  background: transparent !important;
}
.bg-white {
  background: #ffffff !important;
}
.bg-warn {
  background-color: var(--warn-color) !important;
}
.bg-accent {
  background-color: var(--accent-color) !important;
}
.bg-success {
  background-color: var(--primary-color) !important;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 1rem !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-05 {
  margin-top: .5rem !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mr-1 {
  margin-right: 1rem !important;
}
.mr-05 {
  margin-right: .5rem !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-05 {
  margin-bottom: .5rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.ml-05 {
  margin-left: .5rem !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.pt-1 {
  padding-top: 1rem !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pr-1 {
  padding-right: 1rem !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pb-1 {
  padding-bottom: 1rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-1 {
  padding-left: 1rem !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 1rem !important;
}
.p-05 {
  padding: .5rem !important;
}

.light-gray {
  background: rgba(0, 0, 0, .024);
}

.fz-1 {
  font-size: 1rem !important;
}
.fz-2 {
  font-size: 2rem !important;
}
.word-break {
  word-break: break-word;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-link {
  text-decoration: underline;
}
.font-light {
  font-weight: 300 !important;
}
.font-normal {
  font-weight: normal !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}
.text-underline {
  text-decoration: underline;
}
.text-muted {
  color: var(--secondary-text) !important;
}
.text-warn {
  color: var(--warn-text-color) !important;
}
.text-sm {
  font-size: .813rem;
}
.list-item-active {
  border-left: 3px solid;
}
.material-icons.icon-sm {
  font-size: 18px !important;
  line-height: 18px !important;
  height: 18px;
  width: 18px;
}
.material-icons.icon-xs {
  font-size: 13px !important;
  line-height: 13px;
  height: 13px;
  width: 13px;
}

[mat-lg-button] {
  padding: 0 32px !important;
  font-size: 18px;
  line-height: 56px !important;
}

.logo-group {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.logo-clinic {
  max-width: 200px;
}
.toolbar-avatar {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 24px;
}
.toolbar-avatar > img {
  width: 40px !important;
  border-radius: 50%;
}
.toolbar-avatar.md,
.toolbar-avatar.md > img {
  width: 48px !important;
  height: 48px;
}
.toolbar-avatar > .status-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 25px;
  right: -2px;
  border: 2px solid #ffffff;
  background: #ccc;
}
.full-width {
  width: 100% !important;
}
.dnd-item {
  cursor: move;
  cursor: grab;
}

.ql-container .ql-editor {
  min-height: 200px;
}
.chart {
  display: block;
  width: 100%;
}
.form-error-msg {
  color: #f44336;
  display: block;
  padding: 5px 0;
}

.default-table {
  text-align: left;
}
.default-table > thead  tr th {
  font-weight: 400;
  padding: .9rem 1.2rem;
  border-bottom: 1px solid rgba(0, 0, 0, .12);
}
.default-table tbody tr td {
  padding: .9rem 1.2rem;
}
.app-error {
  text-align: center;
  width: 320px;
  max-width: 320px;
  margin: 0 auto;
}
.app-error .error-icon {
  height: 120px;
  width: 120px;
  font-size: 120px;
  float: left;
}
.app-error .error-text {
  float: right;
  width: 200px;
  max-width: 200px;
}
.app-error .error-title {
  font-size: 5rem;
  font-weight: 900;
  margin: 0;
}
.app-error .error-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
}
.app-error .error-actions {
  width: 100%;
  overflow: hidden;
  min-height: 54px;
  margin-top: 100px;
}

@media (max-width: $media-max-width) {
  .accordion-handle {
    flex-direction: column !important;
  }
  .app-error .error-icon {
    height: 100px;
    width: 100px;
    font-size: 100px;
  }
  img:not(.mat-mdc-card-image) {
    &.logo-clinic {
      max-height: 55px;
    }
  }
}

.country-selector {
  margin-top: 12px !important;
}

.mat-form-field-appearance-outline {
  .country-selector {
    margin-top: -4px !important;
  }
}
