.mat-mdc-table {
  &.mat-table--wide {
    width: 100%;
    border-collapse:separate;
    border-spacing: 0 1em;
    .mat-mdc-header-cell {
      font-size: 16px;
    }
    .mat-form-field-infix {
      width: auto !important;
    }
    .mat-mdc-footer-cell {
      padding: 0.75rem;
      font-weight: 500;
    }
  }
  &.mat-table--small {
    .mat-mdc-header-cell {
      padding: .35rem;
    }
    td {
      text-align: left;
      padding-left: 15px;
    }
  }
  &.mat-table--bordered {
    width: 100%; // border: 1px solid #f2f2f2;
    th.mat-header-cell {
      text-align: center;
    }
    th.mat-mdc-header-cell,
    td.mat-mdc-cell {
      // white-space: nowrap;
      border-right: 1px solid #f2f2f2;
      padding: 5px;
    }
  }
  &.mat-table--striped {
    .mat-mdc-row:nth-of-type(odd) {
      &,
      .mat-mdc-cell {
        background-color: lighten(#ededed, 5%);
      }
    }
  }
  &.mat-table--actionable {
    .mat-mdc-row,
    .mat-mdc-cell {
      cursor: pointer;
    }
  }
  caption {
    background-color: #ededed; // border: 1px solid #f2f2f2;
    border-bottom: 0;
    font-weight: 500;
    font-size: 110%;
    letter-spacing: 0.05em;
    padding: 1em 0 0.5em;
  }
  .mat-mdc-header-cell {
    padding: .75rem;
    padding-left: 15px !important;
    text-align: left;
    box-sizing: border-box;
    color: var(--primary-text-lighter-color) !important;
  }
  .multiple-answers {
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
  .mat-mdc-row {
    &.hasFooter .mat-mdc-cell {
      border-bottom: 0;
    }
    .on-hover {
      visibility: hidden;
    }
    &:hover .on-hover {
      visibility: visible;
    }
  }
  .mat-mdc-cell {
    font-size: 1rem;
    padding: .75rem;
    vertical-align: middle;
    .label {
      font-weight: 500;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
  .mat-mdc-header-cell,
  .mat-mdc-cell {
    &.mat-cell-numeric {
      text-align: right;
    }
  }
}

// Mat table responsive
@media (max-width: 599px) {
  .mat-mdc-table[appMatTableResponsive] {
    .mat-mdc-header-row {
      display: flex;
      flex-wrap: wrap;
      height: auto;
      position: sticky;
      top: 0;
      z-index: 2;

      th.mat-mdc-header-cell {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 1rem 0.5rem;
        justify-content: space-around;

        & > .mat-sort-header-container {
          padding-left: 15px; // padding is used to center the header table cells, due to the width of the sort icon
        }

        &:not([mat-sort-header]),
        &.mat-sort-header-disabled,
        &.mat-column-actions {
          display: none;
        }
      }
    }

    .mat-mdc-row {
      height: auto;

      td.mat-mdc-cell:not(.mat-column-actions) {
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 0.5rem;
        text-align: right;
        word-break: break-word;

        & > * {
          grid-column: 2;
        }

        &:before {
          content: attr(data-column-name);
          text-align: left;
          font-weight: 500;
          color: #9E9E9E;
        }
      }

      td.mat-column-actions {
        display: flex;
        justify-content: center;
        padding: 0 0.5rem;

        & > button {
          margin: 0.5rem 0;
        }
      }

      &:last-of-type td:last-of-type {
        border: none;
      }

      &:not(:last-of-type) td:last-of-type {
        border-bottom: 0.5rem solid #fafafa;
      }
    }
  }

  .mat-mdc-card,
  .mat-expansion-panel {
    .mat-mdc-table[appMatTableResponsive] {
      border: 0.5rem solid #fafafa;
    }
  }
}
