@use '@angular/material' as mat;

@import '../ppal-themes';

/**
 * Go through the each defined theme and apply it to whatever content this mixin has
 *
 * @param {Boolean} $encapsulated - Should the generated rules compatible with encapsulated components?
 */
@mixin apply-themes($themes, $encapsulated: false) {
  @each $class-name, $theme in $themes {
    // Set the theme as global so it can be accessible from outside
    $theme: $theme !global;

    // If encapsulated...
    @if ($encapsulated) {
      // :host ::ng-deep .theme-#{$class-name} {
      //   @content;
      // }

      // Do everything inside a host context
      :host-context(.theme-#{$class-name}) {
        @content;
      }
    } @else {
      // Do everything openly
      .theme-#{$class-name} {
        @content;
      }
    }
  }
}

@mixin apply-console-themes($encapsulated) {
  @include apply-themes($ppal-console-themes, $encapsulated) {
    @content;
  }
}

@mixin apply-portal-themes($encapsulated) {
  @include apply-themes($ppal-portal-themes, $encapsulated) {
    @content;
  }
}

@mixin setup-theme($theme) {
  $color: map-get($theme, color);

  $background: map-get($color, background);
  $foreground: map-get($color, foreground);
  $primary: map-get($color, primary);
  $accent: map-get($color, accent);
  $warn: map-get($color, warn);
  $is-dark-theme: map-get($color, is-dark);

  body {
    // base variables
    --base: #{map-get($foreground, base)};
    --text-color: #{map-get($foreground, text)};
    --hint-text-color: #{map-get($foreground, hint-text)};
    --disabled-text-color: #{map-get($foreground, disabled-text)};
    --divider: #{map-get($foreground, divider)};

    // primary variables
    --primary-color: #{map-get($primary, 500)};
    --primary-lighter-color: #{map-get($primary, 100)};
    --primary-light-200-color: #{map-get($primary, 200)};
    --primary-light-300-color: #{map-get($primary, 300)};
    --primary-light-color: #{map-get($primary, 400)};
    --primary-darker-color: #{map-get($primary, 700)};
    --primary-text-color: #{map-get($primary, 600)};
    --primary-text-lighter-color: #{map-get($primary, 300)};
    --primary-text-darker-color: #{map-get($primary, 900)};
    --primary-background: #{mat.get-color-from-palette($primary)};
    --primary-light-background: #{mat.get-color-from-palette($primary, 200)};
    --primary-contrast-color: #{mat.get-color-from-palette($primary, default-contrast)};
    --primary-raised-button-background-color: #{mat.get-color-from-palette($primary, raised-button)};

    // secondary variables
    --secondary-text: #{mat.get-color-from-palette(
        $foreground,
        secondary-text,
        if($is-dark-theme, 0.7, 0.6)
      )};

    // accent variables
    --accent-color: #{map-get($accent, 500)};
    --accent-lighter-color: #{map-get($accent, 100)};
    --accent-light-color: #{map-get($accent, 400)};
    --accent-darker-color: #{map-get($accent, 700)};
    --accent-text-color: #{map-get($accent, 600)};
    --accent-text-lighter-color: #{map-get($accent, 300)};
    --accent-text-darker-color: #{map-get($accent, 900)};
    --accent-background: #{mat.get-color-from-palette($accent)};
    --accent-light-background: #{mat.get-color-from-palette($accent, 200)};
    --accent-contrast-color: #{mat.get-color-from-palette($accent, default-contrast)};

    // warn variables
    --warn-color: #{map-get($warn, 500)};
    --warn-text-color: #{map-get($warn, 500)};

    // background variables
    --background: #{map-get($background, background)};
    --disabled-background: #{map-get($background, disabled-button)};
    --card-background: #{map-get($background, card)};
    --dialog-background: #{map-get($background, dialog)};
    --hover-background: #{map-get($background, hover)};
    --selected-background: #{map-get($background, selected-button)};
    --status-bar-background: #{map-get($background, status-bar)};

    background-color: map-get($background, background);
    color: map-get($foreground, text);
  }
}

@mixin setup-console-material() {
  // Setup Material and necessary style vars
  @include mat.core();

  // Include default theme once in order to setup a basic theme for the application.
  // The main reason for doing this here is to set the typography once since it is the same for all themes.
  // Otherwise, Material will warn about style duplication.
  @include mat.all-component-themes($default-console-theme);

  @include apply-themes($ppal-console-themes, false) {
    @if $theme != $default-console-theme {
      @include mat.all-component-colors($theme);
    }

    @include setup-theme($theme);
  }
}

@mixin setup-portal-material() {
  // Setup Material and necessary style vars
  @include mat.core();

  // Include default theme once in order to setup a basic theme for the application.
  // The main reason for doing this here is to set the typography once since it is the same for all themes.
  // Otherwise, Material will warn about style duplication.
  @include mat.all-component-themes($default-portal-theme);

  @include apply-themes($ppal-portal-themes, false) {
    @if $theme != $default-portal-theme {
      @include mat.all-component-colors($theme);
    }

    @include setup-theme($theme);
  }
}
